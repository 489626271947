import { FWS_BASE_URL } from "@fwa/src/services/apiClient";
import { type SuperTeamMemberSortType } from "@fwa/src/types";

export const fwsUrlPartnerPageSlug = ({ slug }: { slug: string }): string =>
  `${FWS_BASE_URL}/pages/partner/${slug}`;

const memberTeamTeamPagesSort = {
  alpha: `&order[0][orderBy]=title`,
  amount: `&order[0][orderBy]=donationsTotalAmount&order[0][orderDirection]=desc&order[1][orderBy]=title`,
  recent: `&order[0][orderBy]=created&order[0][orderDirection]=desc`,
};

export const fwsUrlPartnerPageSuperTeams = ({
  partnerPageId,
  currentPage = 1,
  itemsPerPage = 50,
  sort = "amount",
}: {
  partnerPageId: string;
  currentPage?: number;
  itemsPerPage?: number;
  sort?: SuperTeamMemberSortType;
}): string =>
  `${FWS_BASE_URL}/partnerpages/${partnerPageId}/superteams?offset=${
    Math.max(currentPage - 1, 0) * itemsPerPage
  }&limit=${itemsPerPage}${sort ? `${memberTeamTeamPagesSort[sort]}` : ""}`;
