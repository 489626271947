import useSWR, {
  type Key,
  type KeyedMutator,
  type SWRConfiguration,
  type SWRResponse,
} from "swr";

import { fetcher } from "@fwa/src/services/apiClient";
import { trackDataDogError } from "@fwa/src/services/dataDog";
import { type FwsResponseData } from "@fwa/src/types";

export type UseSwrGetResponse<ResponseDataType> = Omit<
  SWRResponse,
  "data" | "error"
> & {
  totalItems: number;
  data: ResponseDataType | null;
  error: Error | null;
  mutate: KeyedMutator<ResponseDataType>;
};

export const useSwrGet = <ResponseDataType>(
  key: Key,
  config?: SWRConfiguration,
): UseSwrGetResponse<ResponseDataType> => {
  // I can't controll swr types
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    typeof key === "string" && key?.length ? key : null, // only fetch data if we have a url
    fetcher,
    config,
  );

  const fwaResponseData = data as FwsResponseData<unknown>;
  const typedData = data as ResponseDataType;
  const typedError = error as Error;

  // This only appears on FWS responses tha return lists
  const totalItems: number = fwaResponseData?.metadata?.count
    ? fwaResponseData.metadata.count
    : 0;

  if (error) {
    trackDataDogError(typedError, {
      component: "useSwrGet",
      key,
      message: typedError.message,
    });
  }

  return {
    data: typedData || null,
    error: typedError || null,
    mutate: mutate as KeyedMutator<ResponseDataType>,
    isLoading,
    isValidating,
    totalItems,
  };
};

export default useSwrGet;
