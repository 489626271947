import { type ReactNode } from "react";
import { ErrorPage } from "@fwa/src/components/ErrorPage";
import { type ResponseError } from "@fwa/src/types";

type Props = {
  error?: Error | ResponseError;
  children: ReactNode;
};

export const ErrorPageWrapper = ({ error, children }: Props) =>
  typeof error !== "undefined" && !!error ? (
    <ErrorPage
      statusCode={
        error && "status" in error && error.status ? error.status : 500
      }
      error={error}
    />
  ) : (
    // force ReactNode to JSX type
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );

export default ErrorPageWrapper;
