import { FWS_BASE_URL } from "@fwa/src/services/apiClient";

// This works with uuid or slug
export const fwsUrlPage = ({ pageId }: { pageId: string }): string =>
  pageId.length ? `${FWS_BASE_URL}/pages/${pageId}` : "";

export const fwsUrlPageShareCount = (
  pageEntity: string,
  pageId: string,
): string => {
  type PageEndPoint = {
    FundraisingPage: string;
    [key: string]: string;
  };
  const pageEndPoint: PageEndPoint = {
    FundraisingPage: "fundraisingpages",
    // other endpoints will populate this obj in the future
  };

  return `${FWS_BASE_URL}/${pageEndPoint[pageEntity]}/${pageId}/updatesharescount`;
};

export default fwsUrlPage;
