import { fetcher, FWS_BASE_URL } from "@fwa/src/services/apiClient";
import { fwsUrlPage } from "@fwa/src/services/pageService";

import {
  type TeamMemberSortType,
  type TeamPageType,
  type PageType,
} from "@fwa/src/types";

export const fwsUrlTeamSlug = ({ slug }: { slug: string }): string =>
  `${FWS_BASE_URL}/pages/team/${slug}`;

// Update page with partial data, returns a promise, resolves to page data
// this should probably live in pages service
export const updatePage = async ({
  data,
  pageId,
}: {
  data: Partial<PageType>;
  pageId: string;
}): Promise<PageType | void> =>
  fetcher<PageType>(`${fwsUrlPage({ pageId })}`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });

export const createTeam = (title: string): Promise<TeamPageType | void> => {
  const teamPagePromise = fetcher<TeamPageType>(`${FWS_BASE_URL}/teams`, {
    method: "POST",
    body: JSON.stringify({ title }),
  });
  return teamPagePromise;
};

export const fwsUrlTeam = ({ teamId }: { teamId: string }): string =>
  `${FWS_BASE_URL}/team/${teamId}`;

// Update page with partial data, returns a promise, resolves to page data
export const updateTeam = ({
  data,
  teamId,
}: {
  data: Partial<TeamPageType>;
  teamId: string;
}): Promise<TeamPageType> =>
  fetcher(`${fwsUrlTeam({ teamId })}`, {
    method: "PATCH",
    body: JSON.stringify(data),
  }).then((res) => res as TeamPageType);

const defaultFilter = `&membershipStatus[0]=leader&membershipStatus[1]=member&filter[0][filterBy]=pageStatus.pageStatusCode&filter[0][filterMatch]=%21%3D&filter[0][filterValue]=unpublished`;

const memberSort = {
  alpha: `&order[0][orderBy]=fundraiser.forename&order[1][orderBy]=fundraiser.surname`,
  amount: `&order[0][orderBy]=fundraisingPage.donationsTotalAmount&order[0][orderDirection]=desc`,
  recent: `&order[0][orderBy]=created&order[0][orderDirection]=desc`,
  leader: `&order[0][orderBy]=membershipStatus.membershipStatusName&order[1][orderBy]=fundraiser.forename&order[2][orderBy]=fundraiser.surname`,
};

export const fwsUrlTeamMembers = ({
  teamId,
  currentPage = 1,
  itemsPerPage = 50,
  sort = "leader",
  loggedInFundraiserId,
}: {
  teamId: string;
  currentPage?: number;
  itemsPerPage?: number;
  sort?: TeamMemberSortType;
  loggedInFundraiserId?: string;
}): string =>
  `${FWS_BASE_URL}/teams/${teamId}/teammembers?offset=${
    Math.max(currentPage - 1, 0) * itemsPerPage
  }&limit=${itemsPerPage}${defaultFilter}${sort ? `${memberSort[sort]}` : ""}${
    loggedInFundraiserId ? `&fundraiserUuid=${loggedInFundraiserId}` : ""
  }`;

export const fwsUrlTeamMemberPromote = ({ memberId }: { memberId: string }) =>
  `${FWS_BASE_URL}/teammembers/${memberId}/promote`;

export const fwsUrlTeamMember = ({ memberId }: { memberId: string }) =>
  `${FWS_BASE_URL}/teammembers/${memberId}`;
