import { FWS_BASE_URL } from "@fwa/src/services/apiClient";
import { type SuperTeamMemberSortType } from "@fwa/src/types";

export const fwsUrSuperTeamPageSlug = ({ slug }: { slug: string }): string =>
  `${FWS_BASE_URL}/pages/unite/${slug}`;

const defaultFilter = `&filter[0][filterBy]=pageStatus.pageStatusCode&filter[0][filterMatch]=NOT%20LIKE&filter[0][filterValue]=unpublished`;

const memberFundraisingPagesSort = {
  alpha: `&order[0][orderBy]=fundraiser.forename&order[1][orderBy]=fundraiser.surname`,
  amount: `&order[0][orderBy]=donationsTotalAmount&order[0][orderDirection]=desc&order[1][orderBy]=fundraiser.forename&order[2][orderBy]=fundraiser.surname`,
  recent: `&order[0][orderBy]=created&order[0][orderDirection]=desc`,
};

const memberTeamTeamPagesSort = {
  alpha: `&order[0][orderBy]=title`,
  amount: `&order[0][orderBy]=donationsTotalAmount&order[0][orderDirection]=desc&order[1][orderBy]=title`,
  recent: `&order[0][orderBy]=created&order[0][orderDirection]=desc`,
};

export const fwsUrSuperTeamPageFundraisingPages = ({
  teamId,
  currentPage = 1,
  itemsPerPage = 50,
  sort = "amount",
}: {
  teamId: string;
  currentPage?: number;
  itemsPerPage?: number;
  sort?: SuperTeamMemberSortType;
}): string =>
  `${FWS_BASE_URL}/superteams/${teamId}/fundraisingpages?offset=${
    Math.max(currentPage - 1, 0) * itemsPerPage
  }&limit=${itemsPerPage}${defaultFilter}${
    sort ? `${memberFundraisingPagesSort[sort]}` : ""
  }`;

export const fwsUrSuperTeamTeamPages = ({
  teamId,
  currentPage = 1,
  itemsPerPage = 50,
  sort = "amount",
}: {
  teamId: string;
  currentPage?: number;
  itemsPerPage?: number;
  sort?: SuperTeamMemberSortType;
}): string =>
  `${FWS_BASE_URL}/superteams/${teamId}/teams?offset=${
    Math.max(currentPage - 1, 0) * itemsPerPage
  }&limit=${itemsPerPage}${sort ? `${memberTeamTeamPagesSort[sort]}` : ""}`;
