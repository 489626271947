import { useRef, type TouchEvent, type MouseEvent, useEffect } from "react";
import {
  Box,
  LabelWrapper,
  Button,
  type ThemeType,
} from "@cruk/cruk-react-components";

import { isBrowser } from "@fwa/src/utils/browserUtils";
import { DrawingCanvas } from "@fwa/src/components/DrawingCanvas";

import { Column, Row } from "@fwa/src/components/styles";
import { useTheme } from "styled-components";

export const DrawingCanvasControls = () => {
  const theme = useTheme() as ThemeType;
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const canvasWrapperRef = useRef<HTMLDivElement | null>(null);
  // we use refs so we don't cause re-renders loosing current state of doodle
  const colourRef = useRef<HTMLInputElement | null>(null);
  const lineWidthRef = useRef<HTMLInputElement | null>(null);
  const isPainting = useRef(false);

  if (isBrowser) {
    const canvas = canvasRef.current;
    const canvasWrapper = canvasWrapperRef.current;
    if (canvas && canvasWrapper) {
      canvas.width = canvasWrapper?.clientWidth;
      canvas.height = canvasWrapper?.clientHeight;
    }
  }

  const clear = () => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (!canvas || !ctx) return;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const startDraw = () => {
    if (!isBrowser) return;

    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (!ctx) return;
    ctx.beginPath();
    isPainting.current = true;
  };

  const handleMouseMove = (e: MouseEvent) => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (!canvas || !ctx || !isPainting.current || !colourRef || !lineWidthRef)
      return;
    ctx.lineWidth = parseInt(lineWidthRef.current?.value || "0", 10);
    ctx.strokeStyle = colourRef.current?.value || "#000000";
    ctx.lineCap = "round";
    ctx.lineTo(
      e.clientX - canvas.offsetLeft,
      e.clientY - canvas.offsetTop + window.scrollY,
    );
    ctx.stroke();
  };

  const handleTouchMove = (e: TouchEvent) => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    const canvasWrapper = canvasWrapperRef.current;
    if (
      !canvas ||
      !ctx ||
      !canvasWrapper ||
      !isPainting.current ||
      !colourRef ||
      !lineWidthRef
    )
      return;
    ctx.lineWidth = parseInt(lineWidthRef.current?.value || "0", 10);
    ctx.strokeStyle = colourRef.current?.value || "#000000";
    ctx.lineCap = "round";
    ctx.lineTo(
      e.nativeEvent.touches[0].clientX - canvas.offsetLeft,
      e.nativeEvent.touches[0].clientY - canvas.offsetTop + window.scrollY,
    );
    ctx.stroke();
  };

  const endDraw = () => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (!canvas || !ctx) return;
    isPainting.current = false;
    ctx.beginPath();
  };

  useEffect(() => {
    if (isBrowser) {
      const canvas = canvasRef.current;
      const canvasWrapper = canvasWrapperRef.current;
      if (canvas && canvasWrapper) {
        canvas.width = canvasWrapper?.clientWidth;
        canvas.height = canvasWrapper?.clientHeight;
      }
    }
  }, []);

  return (
    <>
      <Box
        id="controls"
        backgroundColor="backgroundMid"
        padding="xs"
        marginBottom="s"
        data-component="drawing-canvas-controls"
      >
        <Row>
          <Box paddingRight="xs" marginBottom="none">
            <LabelWrapper label="Colour">
              <input
                ref={colourRef}
                type="color"
                name="colour"
                defaultValue={theme.colors.primary}
              />
            </LabelWrapper>
          </Box>
          <Box paddingRight="xs" marginBottom="none">
            <LabelWrapper label="Width">
              <input
                ref={lineWidthRef}
                type="number"
                id="lineWidth"
                name="lineWidth"
                defaultValue="12"
                style={{ width: "4em", fontSize: "1em" }}
              />
            </LabelWrapper>
          </Box>
          <Column
            style={{
              flexDirection: "column-reverse",
            }}
          >
            <Box paddingRight="xs">
              <Button full onClick={clear} data-cta-type="clear-canvas">
                Clear
              </Button>
            </Box>
          </Column>
        </Row>
      </Box>
      <Box
        ref={canvasWrapperRef}
        style={{ width: "100%", height: "50vh", border: "solid 1px black" }}
      >
        <DrawingCanvas
          ref={canvasRef}
          handleMouseDown={startDraw}
          handleTouchStart={startDraw}
          handleMouseUp={endDraw}
          handleTouchEnd={endDraw}
          handleMouseMove={handleMouseMove}
          handleTouchMove={handleTouchMove}
        />
      </Box>
    </>
  );
};

export default DrawingCanvasControls;
