import { useState, useEffect } from "react";
import Head from "next/head";
import NextLink from "next/link";
import { useRouter } from "next/compat/router";
import styled from "styled-components";
import { Heading, Text, Box, Link as A } from "@cruk/cruk-react-components";
import { checkIfDev } from "@fwa/src/utils/envUtils";

import { DrawingCanvasControls } from "@fwa/src/components/DrawingCanvasControls";

import {
  ContentMaxWidth,
  ContentWrapperResponsive,
  Row,
} from "@fwa/src/components/styles";

const image404 = "/assets/images/img/404.svg";

const TextWrapper = styled(Box)`
  float: left;
`;

type Props = {
  statusCode: number;
  error?: Error;
};

export const ErrorPage = ({ statusCode, error }: Props) => {
  const router = useRouter();
  const [missingSlug, setMissingSlug] = useState("");

  const isPotentialFWSDownTime = [500, 503, 504, 408].includes(statusCode);
  const notFound = statusCode === 404;

  const statusMessages: Record<number, string> = {
    400: "Bad Request.",
    401: "You do not have permission to see this page.",
    403: "You need to be logged in to see this page.",
    404: "We can't seem to find the page you are looking for.",
    405: "Method Not Allowed.",
    500: "Internal Server Error.",
  };

  const additionDevInfo = `${statusCode || ""}: ${error?.name || ""}, ${
    error?.message || ""
  }, ${JSON.stringify(error?.stack)}`;

  const title =
    statusMessages[statusCode] || `An unexpected error has occurred`;

  const headingText =
    statusCode === 403 || statusCode === 401
      ? "Access denied"
      : "Something went wrong";

  useEffect(() => {
    if (notFound) {
      setMissingSlug(
        router
          ? router.asPath
              .slice(router.asPath.lastIndexOf("/") + 1)
              .split("?")[0]
          : "",
      );
    }
  }, [notFound, router, setMissingSlug]);

  return (
    <>
      <Head>
        <title>{`Error ${statusCode}: ${title}`}</title>
      </Head>
      <ContentMaxWidth>
        <ContentWrapperResponsive
          marginTop="l"
          backgroundColor="backgroundLight"
          marginBottom="l"
        >
          <Row>
            {notFound && (
              <Box>
                <img src={image404} alt="" />
              </Box>
            )}

            <TextWrapper>
              <Heading h1>{headingText}</Heading>
              <Text>Error: {statusCode}</Text>
              {checkIfDev() && <Text>{additionDevInfo}</Text>}
              <Text>{title}</Text>
              {!!isPotentialFWSDownTime && (
                <Text>
                  {`Sorry this page isn't available right now. Please refresh the page. If that doesn't work, try again in 10 minutes or draw something below while you wait.`}
                </Text>
              )}
              {!!missingSlug.length && (
                <Text>
                  {`Please check the web address and try again or you can try `}
                  <NextLink
                    href={`/?q=${missingSlug}`}
                    data-cta-type="link-return-page"
                  >
                    <A as="span">searching</A>
                  </NextLink>
                  {` for what you are looking for.`}
                </Text>
              )}
            </TextWrapper>
          </Row>
          {!!isPotentialFWSDownTime && (
            <Box marginTop="l">
              <DrawingCanvasControls />
            </Box>
          )}
        </ContentWrapperResponsive>
      </ContentMaxWidth>
    </>
  );
};

export default ErrorPage;
