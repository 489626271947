/* eslint-disable react/no-unused-prop-types */
import {
  type MouseEventHandler,
  type Ref,
  type TouchEventHandler,
  forwardRef,
  memo,
} from "react";

type Props = {
  handleMouseDown: MouseEventHandler<HTMLCanvasElement>;
  handleMouseUp: MouseEventHandler<HTMLCanvasElement>;
  handleMouseMove: MouseEventHandler<HTMLCanvasElement>;
  handleTouchStart: TouchEventHandler<HTMLCanvasElement>;
  handleTouchEnd: TouchEventHandler<HTMLCanvasElement>;
  handleTouchMove: TouchEventHandler<HTMLCanvasElement>;
};

// we need a component with a memo so it doesn't re-render or we will loose what we have drawn

// eslint-disable-next-line react/display-name
export const DrawingCanvas = memo(
  forwardRef((props: Props, ref: Ref<HTMLCanvasElement>) => (
    <canvas
      style={{ touchAction: "none" }}
      ref={ref}
      id="whiteboard"
      onMouseDown={props.handleMouseDown}
      onTouchStart={props.handleTouchStart}
      onMouseUp={props.handleMouseUp}
      onTouchEnd={props.handleTouchEnd}
      onMouseMove={props.handleMouseMove}
      onTouchMove={props.handleTouchMove}
    />
  )),
);

export default DrawingCanvas;
