import { fetcher, FWS_BASE_URL } from "@fwa/src/services/apiClient";
import { fwsUrlPage } from "@fwa/src/services/pageService";

import { type FundraisingPageType } from "@fwa/src/types";

// Update page with partial data, returns a promise, resolves to page data
export const updatePage = ({
  data,
  pageId,
}: {
  data: Partial<FundraisingPageType>;
  pageId: string;
}): Promise<FundraisingPageType> =>
  fetcher(`${fwsUrlPage({ pageId })}`, {
    method: "PATCH",
    body: JSON.stringify(data),
  }).then((res) => res as FundraisingPageType);

export const createFundraisingPage = ({
  data,
  fundraiserUniqueId,
  fundraisingPageTypeUniqueId,
}: {
  data: Partial<FundraisingPageType>;
  fundraiserUniqueId: string;
  fundraisingPageTypeUniqueId: string;
}): Promise<FundraisingPageType> =>
  fetcher(
    `${FWS_BASE_URL}/fundraisers/${fundraiserUniqueId}/fundraisingpagetypes/${fundraisingPageTypeUniqueId}/fundraisingpages`,
    {
      method: "POST",
      body: JSON.stringify(data),
    },
  ).then((res) => res as FundraisingPageType);

export const fwsUrlFundraisingPageSlug = ({ slug }: { slug: string }): string =>
  `${FWS_BASE_URL}/pages/page/${slug}`;

export const fwsUrlPageDefaultHeaderImages = ({ pageId }: { pageId: string }) =>
  `${FWS_BASE_URL}/pages/${pageId}/selectableheaderimages`;

// PUT request
export const fwsUrlSetHeaderImage = ({
  pageId,
  imageId,
}: {
  pageId: string;
  imageId: string;
}) => `${FWS_BASE_URL}/pages/${pageId}/headerimages/${imageId}`;

export const fwsUrlNewHeaderImage = ({ pageId }: { pageId: string }) =>
  `${FWS_BASE_URL}/fundraisingpages/${pageId}/headerimages`;

export const fwsUrlFundraisingPageGalleryImage = ({
  pageId,
}: {
  pageId: string;
}) => `${FWS_BASE_URL}/fundraisingpages/${pageId}/galleryimages`;

export const fwsUrlCheckUrl = ({ url }: { url: string }) =>
  `${FWS_BASE_URL}/pages/checkurl?url=${url}`;

export const fwsUrlGalleryImage = ({
  galleryImageUniqueId,
}: {
  galleryImageUniqueId: string;
}) => `${FWS_BASE_URL}/galleryimages/${galleryImageUniqueId}`;

export const fwsUrlNewPost = ({ pageId }: { pageId: string }) =>
  `${FWS_BASE_URL}/pages/${pageId}/posts`;

export const fwsUrlPost = ({ postId }: { postId: string }) =>
  `${FWS_BASE_URL}/posts/${postId}`;

// new post send binary data receive post with imagegallery image
export const fwsUrlNewPostWithImage = ({ pageId }: { pageId: string }) =>
  `${FWS_BASE_URL}/pages/${pageId}/posts/galleryimages`;

// exitsend binary data receive post with imagegallery image
export const fwsUrlPostImage = ({ postId }: { postId: string }) =>
  `${FWS_BASE_URL}/posts/${postId}/galleryimages`;

const defaultFilter = `&filter[0][filterBy]=pageStatus.pageStatusCode&filter[0][filterMatch]=NOT%20LIKE&filter[0][filterValue]=unpublished`;

const memberSort = {
  alpha: `&order[0][orderBy]=fundraiser.forename&order[1][orderBy]=fundraiser.surname`,
  amount: `&order[0][orderBy]=fundraisingPage.donationsTotalAmount&order[0][orderDirection]=desc&order[1][orderBy]=fundraiser.forename&order[2][orderBy]=fundraiser.surname`,
  recent: `&order[0][orderBy]=created&order[0][orderDirection]=desc`,
};

export const fwsUrlPageParentMemberships = ({
  pageId,
  currentPage = 1,
  itemsPerPage = 50,
  sort = "recent",
}: {
  pageId: string;
  currentPage?: number;
  itemsPerPage?: number;
  sort?: "alpha" | "amount" | "recent";
}): string =>
  `${FWS_BASE_URL}/pages/${pageId}/teammembers?offset=${
    Math.max(currentPage - 1, 0) * itemsPerPage
  }&limit=${itemsPerPage}${defaultFilter}${sort ? `${memberSort[sort]}` : ""}&&membershipStatus[0]=leader&membershipStatus[1]=member`;
