import {
  bowelbabeTheme,
  crukTheme,
  rflTheme,
  su2cTheme,
} from "@cruk/cruk-react-components";
import {
  type FundraisingPageType,
  type SuperTeamPageType,
  type TeamPageType,
  type PageType,
  type BrandNameType,
  type PageEntityType,
} from "@fwa/src/types";
import { fwsUrSuperTeamPageSlug } from "../services/superTeamService";
import { fwsUrlTeamSlug } from "../services/teamService";
import { fwsUrlFundraisingPageSlug } from "../services/fundraisingPageService";
import { fwsUrlPartnerPageSlug } from "../services/partnerService";

// A page may have an owner, this take precedence over fundraiser name
// the display name takes precedence over fore and surnames
export const userNameForPage = (
  page: FundraisingPageType | TeamPageType | SuperTeamPageType | undefined,
): string =>
  typeof page !== "undefined" && !!page
    ? page?.owner
      ? page.owner?.displayName
        ? page.owner.displayName
        : `${page.owner?.forename || ""}${
            page.owner?.surname ? ` ${page.owner?.surname}` : ""
          }`
      : `${page.fundraiser?.forename}${
          page.fundraiser?.surname ? ` ${page.fundraiser?.surname}` : ""
        }`
    : "";

export const firstNameForPage = (
  page: FundraisingPageType | TeamPageType | SuperTeamPageType | undefined,
): string =>
  typeof page !== "undefined" && !!page
    ? page?.owner
      ? page.owner?.forename
        ? page.owner.forename
        : page.owner.displayName || ""
      : page.fundraiser?.forename || ""
    : "";

export const getBrandForPage = (page: PageType): BrandNameType =>
  (page as FundraisingPageType)?.fundraisingPageType?.brand?.name ||
  (page as TeamPageType)?.teamLeaderPage?.fundraisingPageType?.brand?.name ||
  "cruk";

export const getThemeFromBrand = (brandName: string) => {
  if (brandName === "bowelbabe") return bowelbabeTheme;
  if (brandName === "rfl") return rflTheme;
  if (brandName === "su2c") return su2cTheme;
  return crukTheme;
};

export const getRouteForPage = (page: PageType): string => {
  const pageTypeRoutes: Record<PageEntityType, string> = {
    FundraisingPage: "page",
    Team: "team",
    SuperTeam: "unite",
    PartnerPage: "partner",
  };

  const route: string = pageTypeRoutes[page.entityType] || "";
  return route;
};

export const getFwsUrlForPage = (page: PageType): string => {
  const pageEntity = page.entityType;
  const slug = page.url.slice(page.url.lastIndexOf("/") + 1);

  switch (pageEntity) {
    case "FundraisingPage":
      return fwsUrlFundraisingPageSlug({ slug });
    case "Team":
      return fwsUrlTeamSlug({ slug });
    case "SuperTeam":
      return fwsUrSuperTeamPageSlug({ slug });
    case "PartnerPage":
      return fwsUrlPartnerPageSlug({ slug });
    default:
      return fwsUrlFundraisingPageSlug({ slug });
  }
};
